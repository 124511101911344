<template>
  <div>
    <div v-if="user">
      <section class="section">
        <div class="container narrow-container">
          <h4 class="is-size-4 has-text-weight-bold">Profile Details</h4>
          <div>
            <p class="is-overline">Username</p>
            <p>{{ user.username }}</p>
          </div>
          <div>
            <profile-form :user="user" :fields="fields" v-if="user" />
          </div>
        </div>
      </section>
      <section class="section has-padding-vertical">
        <div class="container narrow-container">
          <h4 class="is-size-4 has-text-weight-bold">Subscriptions and Invoices</h4>
          <p>
            Subscriptions and invoices can be
            <a href="/subscriptions" class="has-text-weight-bold">viewed here</a>
          </p>
        </div>
      </section>
      <section class="section">
        <div class="container narrow-container">
          <h4 class="is-size-4 has-small-margin-bottom has-text-weight-bold">Billing Details</h4>
          <h5 class="is-size-6 has-text-weight-bold has-small-margin-bottom has-margin-top">Payment</h5>
          <p v-if="stripeEnv === 'test'" class="tip primary">
            Not live. Just input 4242 4242 4242 4242 - 424 - 24242
          </p>
          <div v-if="user[stripeCustomAttr] && !cardUpdateRequest">
            <p>
              <i class="el-icon-circle-check has-text-success"></i>
              You already have a payment method registered.
              <el-button type="text" @click="cardUpdateRequest = true" class="has-no-padding-vertical"
                >Change payment method
              </el-button>
            </p>
          </div>
          <div v-else>
            <p class="is-size-6-7 has-text-grey">Your credit or debit card information</p>
            <stripe-card @cancel="cardUpdateRequest = false"></stripe-card>
          </div>
          <div v-if="user[stripeCustomAttr]" class="has-margin-top">
            <h5 class="is-size-6 has-text-weight-bold has-small-margin-bottom has-large-margin-top">
              Additional Billing Information
            </h5>
            <div v-if="!showBillingDetails">
              If you want additional information to appear on your invoices and receipts,
              <el-button
                v-if="!showBillingDetails"
                type="text"
                @click="showBillingDetails = true"
                class="has-no-padding-vertical"
                >click here</el-button
              >
            </div>
            <el-collapse-transition>
              <div v-show="showBillingDetails">
                <el-card shadow="never" :body-style="{ 'padding-top': '5px' }">
                  <p class="tip ghost is-size-6-7 has-text-weight-bold">
                    This information will appear on all future invoices
                  </p>
                  <billing-details @cancel="showBillingDetails = false"></billing-details>
                </el-card>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// import { Auth, Storage, Logger } from 'aws-amplify'
import ProfileForm from '@/components/ProfileForm.vue'
import StripeCard from '@/components/StripeCard.vue'
import CustomerInvoices from '@/components/CustomerInvoices.vue'
import BillingDetails from '@/components/Billing/BillingDetails.vue'

export default {
  name: 'Account',
  components: {
    ProfileForm,
    StripeCard,
    CustomerInvoices,
    BillingDetails,
  },
  data() {
    return {
      fields: [{ type: 'string', name: 'email', label: 'Email' }],
      user: null,
      cardUpdateRequest: false,
      isProd: this.$store.state.isProd,
      stripeEnv: this.$store.state.stripeEnv,
      showBillingDetails: false,
    }
  },
  computed: {
    hasStripe() {
      return this.user && this.user['custom:stripeid']
    },
    stripeCustomAttr() {
      if (this.stripeEnv === 'live') {
        return 'custom:stripeid'
      }
      return 'custom:stripeiddev'
    },
  },
  async created() {
    if (this.$store.state.user) {
      const currentUser = await this.$Amplify.Auth.currentUserInfo()
      this.user = {
        username: this.$store.state.user.username,
        ...currentUser.attributes,
      }
    }
  },
}
</script>
