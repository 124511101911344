<template>
  <section>
    <div class="row" v-for="field in fields" v-bind:key="field.name">
      <div v-if="field.type === 'string'">
        <label class="is-overline is-size-6-7 has-text-weight-bold">{{field.label || field.name }}</label>
        <br />
        <el-input class="has-small-margin-top" v-model="user[field.name]"></el-input>
      </div>
    </div>
    <el-button
      type="primary"
      class="save-button"
      :loading="savingEmail"
      v-on:click="save"
    >Update Email</el-button>
  </section>
</template>

<script>
import { Auth } from 'aws-amplify'
import AuthHelper from '@/helpers/auth-helper'

export default {
  name: 'ProfileForm',
  props: ['fields'],
  data() {
    return {
      user: {},
      savingEmail: false,
    }
  },
  async mounted() {
    if (this.$store.state.user) {
      const currentUser = await Auth.currentUserInfo()
      this.user = {
        ...currentUser.attributes,
      }
    }
  },
  methods: {
    async save() {
      const cognitoUser = this.$store.state.user
      if (!this.user || !cognitoUser) {
        return
      }
      try {
        this.savingEmail = true
        await Auth.updateUserAttributes(cognitoUser, this.user)
        await AuthHelper.refreshCurrentSession()
        this.$message({
          message: 'Updated!',
        })
        this.savingEmail = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.save-button {
  margin-top: 15px;
}
</style>
