<template>
  <div :loading="loading">
    <el-form @submit.prevent.native="updateBillingDetails">
      <div class="columns">
        <div class="column has-no-padding-vertical">
          <el-form-item label="Billing Name">
            <el-input
              v-model="customer.name"
              placeholder="Your full name or business name for invoicing and receipts."
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="columns">
        <div class="column has-no-padding-vertical">
          <el-form-item label="First Line">
            <el-input
              v-model="customer.address.line1"
              placeholder="e.g., street, PO Box, or company name"
              required
            ></el-input>
          </el-form-item>
        </div>
        <div class="column has-no-padding-vertical">
          <el-form-item label="Second Line">
            <el-input
              v-model="customer.address.line2"
              placeholder="e.g., apartment, suite, unit, or building"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="columns">
        <div class="column has-no-padding-vertical">
          <el-form-item label="City">
            <el-input
              v-model="customer.address.city"
              placeholder="City, district, suburb, town, or village."
            ></el-input>
          </el-form-item>
        </div>
        <div class="column has-no-padding-vertical">
          <el-form-item label="State">
            <el-input
              v-model="customer.address.state"
              placeholder="State, county, province, or region"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="columns">
        <div class="column has-no-padding-vertical">
          <el-form-item label="Postal Code">
            <el-input v-model="customer.address.postal_code" placeholder="ZIP or postal code"></el-input>
          </el-form-item>
        </div>
        <div class="column has-no-padding-vertical">
          <el-form-item label="Country">
            <br />
            <el-select v-model="customer.address.country" filterable placeholder="Select">
              <el-option
                v-for="item in countryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <el-button
        native-type="submit"
        type="primary"
        class="has-margin-top"
        :loading="updating"
      >Update Billing Details</el-button>
      <el-button @click="$emit('cancel')" type="text" class="has-margin-top">Cancel</el-button>
    </el-form>
  </div>
</template>

<script>
import { updateStripeCustomer, getStripeCustomer } from '@/api/stripe.service'
import countryCodes from '@/helpers/ISO3166-1.alpha2.js'

const countryOptions = Object.keys(countryCodes).map((code) => ({ value: code, label: countryCodes[code] }))
const defaultAddress = {
  line1: '',
  line2: '',
  city: '',
  country: '',
  postal_code: '',
  state: '',
}

export default {
  name: 'BillingDetails',
  data() {
    return {
      loading: true,
      updating: false,
      countryOptions,
      customer: {
        name: '',
        address: defaultAddress,
      },
    }
  },
  async created() {
    try {
      const { data: customer } = await getStripeCustomer()
      this.customer.name = customer.name
      if (customer.address) {
        this.customer.address = Object.assign({}, defaultAddress, customer.address)
      }
    } catch (e) {
      console.log(e)
      this.$message.error('Error fetching customer. Please refresh or contact support.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async updateBillingDetails() {
      this.updating = true
      if (!this.customer.address.line1) {
        return
      }
      try {
        const addressUpdate = {}
        Object.keys(defaultAddress).forEach((key) => {
          if (this.customer.address[key]) {
            addressUpdate[key] = this.customer.address[key]
          }
        })
        await updateStripeCustomer({
          name: this.customer.name,
          address: addressUpdate,
        })
        this.$message.success('Updated billing information')
        this.updating = false
      } catch (e) {
        console.log(e)
        this.$message.error('Error updating billing details')
      }
    },
  },
}
</script>
